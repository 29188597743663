import CryptoJS from "crypto-js";
const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY;
const ivKey = process.env.REACT_APP_ENCRYPTION_IV_KEY;

export const lengthAndValidCharactersInStringCheck = (input) => {
  if (!input) {
    return {
        status: false,
        message: 'field cannot be empty'
    }
  }
  const trimmedInput = input.trim();
  const invalidPattern = /[<>]/;
  if(input.length > 255){
    return {
        status: false,
        message: 'input length must be less than 255 characters'
    }
  }
  if(invalidPattern.test(input)){
    return {
        status: false,
        message: 'input cannot contain < or > characters.'
    }
  }
  return {
    status: true,
    message: ''
  }
  
};

export const decryptData = (encryptedData) => {
  try {
    const key = CryptoJS.enc.Base64.parse(secretKey);

    const iv = CryptoJS.enc.Utf8.parse(ivKey);
    const encryptedBytes = CryptoJS.enc.Base64.parse(encryptedData);
    const decrypted = CryptoJS.AES.decrypt(
      { ciphertext: encryptedBytes },
      key, 
      {
        iv: iv,
        mode: CryptoJS.mode.CBC, 
        padding: CryptoJS.pad.Pkcs7,
      }
    );

    const plaintext = decrypted.toString(CryptoJS.enc.Utf8);

    if (!plaintext) {
      console.error("Decryption failed. Resulting plaintext is empty.");
      return ""
    }

    return plaintext;
  } catch (error) {
    console.error("Error decrypting data:", error.message);
    return ""
  }
}
