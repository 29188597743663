import React, { useEffect } from "react";
import {
  Typography,
  IconButton,
  MenuItem,
  Toolbar,
  Button,
  Menu,
  Box,
  Tooltip
} from "@mui/material";
import ListItemIcon from "@mui/material/ListItemIcon";
import KeycloakService from "../../services/KeycloakService";
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import "../../App.css";
import { useNavigate } from "react-router-dom";
import { ApplicationRoutes } from "../../routes";
import { useAppDispatch } from "../../services/StoreHooks";
import { Stack } from "@mui/system";
import { onMessageListener } from "../../services/FireBaseService";
import IconWithContent from "./IconWithContent";
import { notificationCount } from "../../services/NotifcationServices";
import { MILLER_UI_TEXT } from "../../textConstant";
import LanguageSwitcher from "../../LanguageSwitcher";
import useTranslatewrapper from "../useTranslateWrapper";
import { decryptData } from "../../commonFunctions";

export default function ToolbarCompnent(props) {
  const {translate} = useTranslatewrapper()

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [validRoutes, setValidRoutes] = React.useState([]);
  const [notification, setNotification] = React.useState({body: '', data: ''});
  const [notificationNumber, setNotificationNumber] = React.useState([]);

  // const hideLanguageSwitcher = props.isInspector || props.isMonitor || props.isSuperAdmin
  const hideLanguageSwitcher = true


  const dispatch = useAppDispatch();
    // Effect to listen to new notifications and update count
useEffect(() => {
  process.env.REACT_APP_FIREBASE_VAPID_KEY && callFireBaseListener()//
  fetchNotificationCount()
}, [notification, notificationNumber])

  const navigate = useNavigate();

  const onDestroy = () => {
    dispatch({
      type: "PEOPLE_PAGE",
      data: {
        id: "",
      },
    });
  };

  const navigateRoute = (routePath) => {
    navigate(routePath);
    if (props.hideMenu) props.hideMenu(false);
    onDestroy();
  }
  const profile = ApplicationRoutes.find((obj) => obj.title === "Profile");

  const handleDrawerToggle = () => {
    props.setOpen(!props.Open);
  };

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => { }, []);
  // function to call update count every time firebase listens some notification
  const callFireBaseListener = () => {
    onMessageListener()
    .then((payload) => {
      setNotification({body: payload.notification.body, data: payload.data})
    }).catch(err => console.log('failed: ', err));
  }

  const fetchNotificationCount = async()=>{
    const data = await notificationCount();
    setNotificationNumber(data?.data)
    if (data.data || data.data === 0 ) {
      dispatch({
          type: "SHOW_COUNT",
          data: data?.data,
      });
    }
  }
  
  return (
    <Toolbar className="space-between-container">

      <IconButton
        aria-label="open drawer"
        edge="start"
        onClick={handleDrawerToggle}
        sx={{ mr: 2, display: { sm: 'none' } }}
      >
        <MenuIcon />
      </IconButton>
      <Box>
        <Stack direction="row" alignItems={"center"}>
          <Typography variant="h6" noWrap component="div">
          <img src="/assets/images/Sri-Lanka-Government.jpg" alt="logoimage" height={90} width={160} style={{objectFit:"contain"}} />
          </Typography>
          {/* <Typography variant="h6" noWrap component="div">
            <img src="/assets/images/apscsc.png" alt="toolbar-logo" className="toolbar-logo" />
          </Typography> */}
          {/* <Box sx={{width:"28%" ,height: '80%'}} noWrap>

          <Typography m={1} color="#228B22" sx={{fontSize:"0.8rem" , fontWeight:"bold" ,borderRight: '1.8px solid #cbcbcb', padding: '0.5em'}} >
            FOOD CORPORATION OF INDIA
          </Typography>
          </Box> */}
          {/* <Typography  noWrap component="div" mt={3} color="#228B22"  sx={{fontSize:"0.8rem" , fontWeight:"bold", marginLeft: "10px"}}>
            FORTIFIED RICE QUALITY MANAGEMENT SYSTEM
          </Typography> */}
        </Stack>
      </Box>
      <Box flex={1} display={"flex"} justifyContent={"center"}>
        <Typography  noWrap component="div" mx={3} color="#228B22"  fontWeight={"bold"} fontSize={28}>
          FRQMS
        </Typography>
      </Box>

      <Typography variant="h6" noWrap component="div" sx={{display: "flex", alignItems: "center"}}>
        {
          !hideLanguageSwitcher &&
          <div style={{height: '50%', marginLeft:'10px',marginRight:'10px'}}><LanguageSwitcher language={props.language} /></div>
        }
        {  !props.isSuperAdmin && !props.isMonitor && !props.isInspector &&
          <IconWithContent/>
        }
        <div style={{ fontSize: '14px', lineHeight: '1' }} className="text-primary text-capitalize">{decryptData(KeycloakService.getUserDetail()?.firstName)} {decryptData(KeycloakService.getUserDetail()?.lastName)}
          <Tooltip title={KeycloakService.getUserDetail()?.role?.replace(/MILLER/g, MILLER_UI_TEXT) } placement="bottom" arrow>
            <div style={{ whiteSpace: "nowrap", maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", cursor: "default" }}>{KeycloakService.getUserDetail()?.role?.replace(/MILLER/g, MILLER_UI_TEXT)}</div>
          </Tooltip>
        </div>
        <Button onClick={(event) => handleMenu(event)}>
          <img src="/assets/images/Avatar.png" alt="user-avatar" />
        </Button>
        <Menu
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          {/* <MenuItem onClick={() => navigateRoute(profile.path)}>
            <ListItemIcon>
              <AccountBoxIcon fontSize="small" color="primary" />
            </ListItemIcon>
            Profile
          </MenuItem> */}
          <MenuItem onClick={KeycloakService.doLogout}>
            <ListItemIcon>
              <Logout fontSize="small" color="primary" />
            </ListItemIcon>
            {translate('logout')}
          </MenuItem>
        </Menu>
      </Typography>

    </Toolbar>
  );
}

