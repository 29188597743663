import React from "react";

export const ErrorPage = () => {
  return (
    <div className="main-content-overlay">

    {/* <div> */}
      <h1 className="text-danger fw-bold pt-4 mb-4">{`Oops! Something went wrong.`}</h1>
      <p>We can't find the resource that you are looking for. Please use the menus to navigate to a specific section</p>
    {/* </div> */}
    </div>
  );
};
