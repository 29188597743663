import React, { useEffect, useState } from "react";
import { withTheme } from "@rjsf/core";
import Theme from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector,useDispatch} from "react-redux";
import { Button } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import "../../../App.css";
import {
  ACTION_STATE_DATE_LABELS,
  ACTION_CONFIRMATION_MESSAGES,
} from "../GenericForm/ActionModalConstants";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Checkbox,

} from "@mui/material";
import { useAppDispatch } from "../../../services/StoreHooks";
import moment from "moment";
import ManufacturerDropDown from "../../../pages/Admin/labService/Users/Manufacturer Dropdown/ManufacturerDropdown.js"
import { Box } from "@mui/system";
import useTranslatewrapper from "../../useTranslateWrapper.js";
import { lengthAndValidCharactersInStringCheck } from "../../../commonFunctions.js";

const uischema = {
  SampleSentToLabDate: {
    "ui:options": {
      "yearsRange": [
        2020,
        2023
      ]
    }
    
  },
  Comments: { "ui:widget": "textarea" },
  
};

function SampleSentToLabForm(props) {
             const { handleClose, configuration, categoryId, actions, updateActionState,requestBody,lastActionDate,showLabDropDown , inspectModalData} =
    props;

    const {translate} = useTranslatewrapper()

  const getId = useParams();
  const navigate = useNavigate();
 

  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };
  const event = moment().local();
  
  const currentAction = useSelector((state) => state.actionState);
  const Form = withTheme(Theme);

  const [schema, setSchema] = useState();
  const [formData, setFormData] = useState({SampleSentToLabDate: event.format("yyyy-MM-DD"), Comments :""});
  const [open, setOpen] = React.useState(false);
  const [dateValidated, setDateValidated] = React.useState(false);
  const [globalErr, setGlobalErr] = React.useState([]);
  const [modalDate, setModalDate] = React.useState(moment().local());
  const [data, setData] = useState({});
  const [manufacturerData,setManufacturerData]=useState()
  // const [manufacturerName, setManufacturerName] = useState('')
  const [randomLab,setRandomLab]=React.useState(false);
  const activeLab = true;

  const [SampleSentToLabDate, setSampleSentToLabDate] = useState();
  const dispatch = useAppDispatch();
  const manufacturerId = getId.type === "batch" ? inspectModalData?.manufacturerId : inspectModalData?.targetManufacturerId;
  const checkLab = (currentAction.action == "sentBatchSampleToLabTest" || currentAction.action == "sentLotSampleToLabTest" ) && showLabDropDown
  const handleClickOpenDialog = (e) => {
    if (e) {
      setData({
        SampleSentToLabDate: modalDate,
        Comments: e.formData?.Comments
      });
      setOpen(true);
    } 
  };

  const handleClickCloseDialog = () => {
    setOpen(false);
  };

  const dispatchShowLoader = (showLoader) => {
    dispatch({
      type: "SHOW_LOADER",
      data: {
        showLoader
      },
    });
  };

  useEffect(()=>{
    setDateValidated(validateDate(modalDate))
  },[modalDate])
  useEffect(() => {
   
   
    setSchema({
      type: "object",
      properties: {
        
        Comments: {
          type: "string",
          title: translate("comments"),
        },
      },
      required: ["SampleSentToLabDate"],
    });
  }, []);

  const handleClickYes = async () => {
    dispatchShowLoader(true);
    var StatusUpdate = {
      stateId: actions?.find((element) => element.name === currentAction.action)
        ?.id,
      comments: data?.Comments || "",
      dateOfAction: moment(data.SampleSentToLabDate.toString()).format('yyyy-MM-DD'),
      currentAction,
    } 
    if(checkLab) {StatusUpdate.labId = randomLab?null:Number(manufacturerData);}
    getId.type === "batch" && (StatusUpdate["batchId"] = Number(getId.id));
    getId.type === "lot" && (StatusUpdate["lotId"] = Number(getId.id));
    const updateResult = await updateActionState(StatusUpdate);
    dispatchShowLoader(false);

    if (updateResult?.data) {
      handleClose(false);
      handleClickCloseDialog();
    }
  };
  
  
  const validateDate =(e)=>{
    let arrErr =[];
    if(currentAction.action === "sentBatchSampleToLabTest" ){
      if(new Date(e.$d) < new Date(requestBody.ManufacturingDate)){
        arrErr.push(`sample sent date can not be before Manufacturing date`)
      }
      if(new Date(e.$d) > moment().local()){
        arrErr.push(`sample sent date can not be after Current date`)
      }   
    }
    if(currentAction.action === "lotReceived" ){
      if(new Date(e.$d) < new Date(requestBody.DispatchDate)){
        arrErr.push(`Lot Recived Date can not be before Dispatch date`)
      }
      if(new Date(e.$d) > moment().local()){
        arrErr.push(`Lot Recived Date can not be after Current date`)
      }    
    }
    if( new Date(e.$d) < new Date(requestBody.ReceivedDate)){
      if(currentAction.action === "approved"){
        arrErr.push(`Lot Accepted Date can not be before Lot Received date`)
      }
      if(currentAction.action === "sentLotSampleToLabTest"){
        arrErr.push(`Send Lot sample to lab Date can not be before Lot Received date`)
      }
      
      if(currentAction.action === "toSendBackRejected"){
        arrErr.push(`Lot Rejected Date can not be before Lot Received date`)
      }
      if(currentAction.action === "sentBackRejected"){
        arrErr.push(`Lot Rejected Date can not be before Lot Received date`)
      }
    }
      if(new Date(e.$d) > moment().local()){
        if(currentAction.action === "approved"){
          arrErr.push(`Lot Accepted Date can not be after Current date`)
        }
        if(currentAction.action === "sentLotSampleToLabTest"){
          arrErr.push(`Send Lot sample to Lab Date can not be after Current date`)
        }
        
        if(currentAction.action === "toSendBackRejected"){
          arrErr.push(`Lot Rejected Date can not be after Current date`)
        }
        if(currentAction.action === "sentBackRejected"){
          arrErr.push(`Lot Rejected Date can not be after Current date`)
        }
        
      }     
    let msg = "";
    arrErr.map((err) => (msg += err + "\n"));

    if (arrErr && arrErr.length > 0) {
     setGlobalErr(arrErr)
      dispatchNotification("error", arrErr);
      return false;
    }
    else {
        setGlobalErr([])
        return true;
    }

  }

  const validateLab =()=>{
    let arrErr =[];
    if(randomLab== false && !manufacturerData ){
      arrErr.push(`Select a lab to continue`)
    }          
    let msg = "";
    arrErr.map((err) => (msg += err + "\n"));
    if (arrErr && arrErr.length > 0) {
      setGlobalErr(gErr => gErr.push(...arrErr));
      return false;
    } else {
      setGlobalErr([])
      return true;
    }
  }

  const validateComments =(e)=>{
    if(e.formData?.Comments){
      let validationResult =  lengthAndValidCharactersInStringCheck(e.formData?.Comments)
      if(!validationResult['status']){
        // arrErr.push("comments" +": "+ validationResult['message'])
        globalErr.push("comments" +": "+ validationResult['message'])
        setGlobalErr(globalErr);
        return false;
      }
      return true;
    }
    return true;
  }

  const submit = (e) =>{
    if(checkLab){
      return dateValidated && validateLab() && validateComments(e); //
    }
    else{
      return dateValidated && validateComments(e)
    }
  }
  const showRandomLabCheckbox = false;
  return (
    <div>
      {schema && (
        <>
         <div>
         <Typography style={{ fontWeight: "400", fontFamily: "outfit", marginBottom: "1rem" }}>{translate(ACTION_STATE_DATE_LABELS[currentAction.action])}</Typography>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
                <MobileDatePicker
                  value={modalDate}
                  disableFuture="true"
                  minDate={new Date(lastActionDate)}
                  label={translate(ACTION_STATE_DATE_LABELS[currentAction.action])}
                  inputFormat="DD/MM/YYYY"
                  renderInput={(params) => <TextField {...params} inputProps={{...params.inputProps, readOnly: true}}  />}
                  onChange={(date)=>{
                    
                    setModalDate(date.$d);
                  }}
                  className="input-date-picker"
                  sx={{ mb: "1", mt: "1",width: "97%" }}
                />
              </LocalizationProvider>
        </div>
          {(checkLab) &&
            (<Box my={2}>
              {showRandomLabCheckbox && <FormControlLabel
                sx={{ width: "45%" }}
                value="start"
                control={<Checkbox />}
                label="Assign to random Lab"
                labelPlacement="end"
                onChange={(event) => {
                  setRandomLab(event.target.checked);
                  setManufacturerData(null)
                }}
              />}
              <ManufacturerDropDown
                setManufacturerData={setManufacturerData}
                // setManufacturerName={setManufacturerName}
                disableDropdown={randomLab && showRandomLabCheckbox}
                isActiveLab={activeLab}
                categoryId={categoryId}
                manufacturerId={manufacturerId}
              />
            </Box>
            )
          }
        <Form
          schema={schema}
          validator={validator}
          uiSchema={uischema}
        
          formData={formData}
          onSubmit={(e) => {
            submit(e)  ? handleClickOpenDialog(e) : dispatchNotification("error", globalErr);
            setGlobalErr([]);
            setFormData(e.formData)  
          }}
        >
         
          <div className="action-buttons-container">
            <Button variant="contained" type="submit">
              {translate(currentAction?.title)}
            </Button>
          </div>
        </Form>
        </>
        
      )}
      <Dialog
        open={open}
        onClose={handleClickCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{translate('confirmationMsg')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to {ACTION_CONFIRMATION_MESSAGES[currentAction.action]}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClickCloseDialog} variant="contained"  sx={{ backgroundColor: 'grey' }}>
          {translate('no')}
          </Button>
          <Button
            type="submit"
            variant="contained"
            onClick={handleClickYes}
            autoFocus
          >
            {translate('yes')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default SampleSentToLabForm;
