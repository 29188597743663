import React, { useState } from "react";
import {
  Button,
  Typography,
  Box,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  InputLabel,
  OutlinedInput,
  FormHelperText,
} from "@mui/material";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { resetPassword } from "../../../../../services/Manage_service";
import { useAppDispatch } from "../../../../../services/StoreHooks";

function ResetPasswordModal(props) {
  const { id, setModalOpen } = props;
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState(null);
  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [isValidPasswordField, setIsValidPasswordField] = React.useState(false)
  const [isValidConfirmPasswordField, setIsValidConfirmPasswordField] = React.useState(false)

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);
  let msg = "";

  const capitalAndNumberRegex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).*$/;
  const specialCharacterRegex = /[\W_]+/;

  const passwordChangeHandler = (e) => {
    setPassword(e.target.value);

    if (e.target.value.trim().length === 0) {
      document.getElementById("password-validate-message").innerHTML =
        "Password can not be Empty";
      document.getElementById("password-validate-message").style.color = "red";
      setIsValidPasswordField(false)
    } else if (e.target.value.length < 8) {
      document.getElementById("password-validate-message").innerHTML =
        "Password must have at least 8 characters and must contain at least one uppercase letter, one lowercase letter, a special character and one number";
      document.getElementById("password-validate-message").style.color = "red";
      setIsValidPasswordField(false)
    } else if (!capitalAndNumberRegex.test(e.target.value.trim())){
      document.getElementById("password-validate-message").innerHTML =
      "Password must contain at least one uppercase letter, one lowercase letter and one number";
      document.getElementById("password-validate-message").style.color = "red";
      setIsValidPasswordField(false)
    } else if (!specialCharacterRegex.test(e.target.value.trim())){
      document.getElementById("password-validate-message").innerHTML =
      "Password must contain at least one special character";
      document.getElementById("password-validate-message").style.color = "red";
      setIsValidPasswordField(false)
    } else if (confirmPassword && e.target.value !== confirmPassword) {
      document.getElementById("password-validate-message").innerHTML =""
      document.getElementById("confirm-password-validate-message").innerHTML =
        "Passwords do not match";
      document.getElementById("confirm-password-validate-message").style.color =
        "red";
        setIsValidPasswordField(true)
        setIsValidConfirmPasswordField(false)
    } else {
      if(document.getElementById("confirm-password-validate-message").innerHTML ==
      "Passwords do not match"){

        document.getElementById("confirm-password-validate-message").innerHTML =
          "";
        setIsValidConfirmPasswordField(true)
      }
      document.getElementById("password-validate-message").innerHTML = "";
      setIsValidPasswordField(true)
    }
  };

  const confirmPasswordChangeHandler = (e) => {
    setConfirmPassword(e.target.value);
    if (e.target.value.trim().length === 0) {
      document.getElementById("confirm-password-validate-message").innerHTML =
        "Password can not be Empty";
      document.getElementById("confirm-password-validate-message").style.color =
        "red";
        setIsValidConfirmPasswordField(false)
    } else if (e.target.value !== password) {
      document.getElementById("confirm-password-validate-message").innerHTML =
        "Passwords do not match";
      document.getElementById("confirm-password-validate-message").style.color =
        "red";
        setIsValidConfirmPasswordField(false)
    } else {
      document.getElementById("confirm-password-validate-message").innerHTML =
        "";
      setIsValidConfirmPasswordField(true)
    }
  };

  // useEffect(() => {
  //   setPasswordData({
  //     Password: password,
  //     ConfirmPassword: confirmPassword,
  //     isValidPassword: (isValidPasswordField && isValidConfirmPasswordField),
  //   });
  // }, [
  //   isValidPasswordField,
  //   isValidConfirmPasswordField,
  // ]);

  const dispatch = useAppDispatch();
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const handlePasswordReset = async () => {
    if(!isValidPasswordField || !isValidConfirmPasswordField){
      return;
    }
    const apiBody = {
      userId : parseInt(id),
      password : password
    }
    const result = await resetPassword(id, apiBody);
    if (result.status === 200) {
      setModalOpen(false);
      dispatchNotification("success", [result.data]);
    }
  };

  return (
    <Box sx={{ textAlign: "center" }}>
      <Typography sx={{ margin: 1 }} variant="h6">
        Reset Password
      </Typography>

      <FormControl variant="outlined" sx={{ width: "80%", my: 1 }}>
        <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          required
          label="Password"
          value={password}
          onChange={(e) => {
            passwordChangeHandler(e);
          }}
        />
        <FormHelperText id="password-validate-message">{msg}</FormHelperText>
      </FormControl>

      <FormControl variant="outlined" sx={{ width: "80%", my: 1 }}>
        <InputLabel htmlFor="outlined-adornment-password">
          Confirm Password
        </InputLabel>
        <OutlinedInput
          id="outlined-adornment-password"
          type={showConfirmPassword ? "text" : "password"}
          endAdornment={
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          }
          label="Confirm Password"
          required
          value={confirmPassword}
          onChange={(e) => {
            confirmPasswordChangeHandler(e);
          }}
        />
        <FormHelperText id="confirm-password-validate-message">
          {msg}
        </FormHelperText>
      </FormControl>
      <Button
        type="submit"
        variant="contained"
        onClick={handlePasswordReset}
        sx={{ my: 2 }}
      >
        Update password
      </Button>
    </Box>
  );
}

export default ResetPasswordModal;
