import React, { useState, useEffect } from "react";

import { Box, Button } from "@mui/material";
import FileUpload from "../FileUploadComponent/FileUpload";
import { TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { OTHER_BUTTON } from "../../../textConstant";
import { cloneDeep } from "lodash";
import useTranslatewrapper from "../../../components/useTranslateWrapper";
import { lengthAndValidCharactersInStringCheck } from "../../../commonFunctions";
import { useAppDispatch } from "../../../services/StoreHooks";

function GetAllFiles({
  getId,
  createFileUploadFields,
  handleFileDelete,
  getFileData,
  setRemovedNames,
  setShowButton,
  disableVendorData,
  sourceCategoryData,
  isPremixAddFromFrk
}) {

  const dispatch = useAppDispatch();
  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const {translate} = useTranslatewrapper()
  const [categoryDocs, setCategoryDocs] = React.useState(
    !isPremixAddFromFrk ?
  [] : 
  [{
    name: 'Test Report',
    id: 1001,
    isMandatory: true

  }]
  );
  //
useEffect(()=>{

  if(isPremixAddFromFrk){
    setCategoryDocs([{
      name: 'Test Report',
      id: 1001,
      isMandatory: true

    }])
  } else{

  
  setCategoryDocs( (getId?.id)
      ? createFileUploadFields
      : createFileUploadFields?.map((item) => {
          return {
            name: item.docType?.name,
            id: item.id,
            uuid: item.docType?.uuid,
            isMandatory: item.isMandatory,
          };
        }) )
      }
},[createFileUploadFields])



  const handleChangeInput = (event, index) => {
    if(event.target.value){
      let arrErr = [];
      let validationResult = lengthAndValidCharactersInStringCheck(event.target.value);
      if (!validationResult['status']) {
        arrErr.push("Filename" + ": " + validationResult['message'])
        dispatchNotification("error", arrErr);
        return;
      }
    }

    if (setShowButton) setShowButton(true);
    const idsToRename = [];
    const data = cloneDeep(categoryDocs);
    (data[index].paths.length > 0) && data[index].paths.forEach(element => {
      idsToRename.push(element.id)
    });
    getFileData((fileData)=> {
      // const fileObj = fileData.find((fO)=> fO.name && !fO.categoryDocId && fO.name === data[index]['name'])
      // if(fileObj) {
      //   fileObj.name = event.target.value;
      // }
      // return [...fileData];

      let newArr = fileData.map(ele => {
        if(!ele.categoryDocId && (ele.name === data[index]['name']) && ((ele.idx != undefined) ? (ele.idx == index) : true) && (ele.id ? idsToRename.includes(ele.id) : true)){
          ele.name = event.target.value
        } 
        return ele;
      })
  
      return [...newArr]
    })
    setCategoryDocs((cDocs) => {
      cDocs[index].name = event.target.value;
      return [...cDocs];
    });
  };

  const addRow = () => {
    if (setShowButton) setShowButton(true);

    !disableVendorData &&
      setCategoryDocs([
        ...categoryDocs,
        {
          name: "",
          paths: [],
        },
      ]);
  };

  const removeRows = (index) => {
    if (Array.isArray(categoryDocs) && index > -1 && !disableVendorData) {
      if (setShowButton) setShowButton(true);

      let data = [...categoryDocs];
      const currentRow = { ...data[index] };
      setRemovedNames((name) => [...name, currentRow.name]);
      data.splice(index, 1);
      setCategoryDocs(data);
    }
  };

  return (
    <div>
      <Box sx={{ display: "flex", flexWrap: "wrap", width: "77.5vw" }}>
        {categoryDocs?.map((obj, idx) => {
          return (
            <Box
              sx={{
                fontSize: "12px",
                // fontWeight:"bold",
                flexBasis: " calc(33% - 20px)",
              }}
            >
              {obj?.id ? (
                <p style={{ marginBotton: "0px" }}>
                  {obj?.name} {obj?.isMandatory == true ? "*" : ""}
                </p>
              ) : (
                <>
                  <TextField
                    disabled={disableVendorData}
                    sx={{ width: "25ch", marginBottom: "1rem" }}
                    name="name"
                    id="outlined-basic"
                    value={obj.name}
                    variant="standard"
                    label={translate('fileName')}
                    required
                    onChange={(e) => handleChangeInput(e, idx)}
                  />
                  <CloseIcon
                    sx={{ fontSize: "20px", marginTop: "1.5rem" }}
                    onClick={() => removeRows(idx)}
                  ></CloseIcon>
                </>
              )}
              {(obj?.id || obj?.name) && (
                <FileUpload
                  callType={obj.id ? "api" : "custom"}
                  info={obj}
                  paths={obj.paths}
                  getFileData={getFileData}
                  handleFileDelete={handleFileDelete}
                  setShowButton={setShowButton}
                  disableVendorData={disableVendorData}
                  isPremixAddFromFrk={isPremixAddFromFrk}
                  idx={idx}
                />
              )}
            </Box>
          );
        })}
      </Box>

      <Button
        disabled={disableVendorData}
        variant="outlined"
        sx={{ marginTop: "10px" }}
        onClick={() => addRow()}
      >
        {translate('additionalDocs')}
      </Button>
    </div>
  );
}

export default GetAllFiles;
