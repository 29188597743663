import React, { useState } from "react";
import {
  Typography,
  TextField,
  MenuItem,
  Button,
  Stack,
  Box,
  Tooltip,
  RadioGroup,
  FormLabel,
  FormControlLabel,
  Radio,
  FormControl,
} from "@mui/material";
import { ModalStyle } from "../DropdownFRK/InternalStyles";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocation, useNavigate } from "react-router-dom";
import "../../App.css";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import BatchSizeComponent from "../../pages/Form/BatchComponent/LotSizeUnits";
import { useAppDispatch } from "../../services/StoreHooks";
import Autocomplete from "@mui/material/Autocomplete";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import { ListboxComponent } from "../InfiniteScroll/ListboxComponent";
import TruckDetailComponent from "../../pages/Form/BatchComponent/TruckDetailTable";
import { getTargetManufacturerFromLicense } from "../../services/FortificationService";
import useTranslatewrapper from "../useTranslateWrapper";
import { lengthAndValidCharactersInStringCheck } from "../../commonFunctions";

export default function DropDownFRk(props) {
  const {translate} = useTranslatewrapper()
  const navigate = useNavigate();
  const currentLocation = useLocation();
  const {
    submitLotData,
    manuFacturerNames,
    unitsOfMeasurement,
    batchRemainingQuantity,
    batchNo,
    categoryId,
    setFRKType,
    frkType,
    batchSizeUnits,
    setQueryparam,
    lastActionDate,
    fetchFrkManufacturers,
    pageNumber,
    manufacturerCount,
    purchaseOrderList,
    setTargetManfId,
    getId,
    batchOrLotCategory
    } = props;
  const dispatch = useAppDispatch();
  const [unit, setUnit] = React.useState("");
  const [lotNo, setLotNo] = React.useState("");
  const [quantity, setQuantity] = React.useState("");
  const [frkManufacturerId, setFrkManufacturerId] = useState("");
  const [batchSizeLot, setBatchSizeLot] = useState([]);
  const [truckDetail, setTruckDetail] = useState([]);
  const [submit, setSubmit] = useState(false);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(moment().local().format("yyyy-MM-DD"));
  const [manufacturerName, setManufacturerName] = useState('');
  const [purchaseName, setPurchaseName]= useState('');
  const [purchaseId, setPurchaseId]= useState("");
  const [commodityId, setCommodityId] = useState("")
  // const isFrk = batchOrLotCategory?.name ==="FRK";
  const isFrk = false;
  const [manufSelectChoice, setManufSelectChoice] = useState('dropDown')
  const [targetManufId, setTargetManufId] = useState('')
  const [targetManufName, setTargetManufName] = useState('')
  const [targetManufLicenseNumber, setTargetManufLicenseNumber] = useState('')
  const [licenseError, setLicenseError] = useState(false)
  let newArr;
  const handleClickOpenDialog = () => {
    let arrErr = [];
    // if (lotNo == "") {
    //   arrErr.push(`lotNo is not selected`);
    // }
    if (frkManufacturerId == "" && !targetManufId) {
      arrErr.push(`Manufacturer Name is not selected`);
    }
    if (value == null) {
      arrErr.push(`Dispatch Date is not selected`);
    }

    if(lotNo){
      let validationResult =  lengthAndValidCharactersInStringCheck(lotNo)
      if(!validationResult['status']){
        arrErr.push("Manufacturer Lot Number" +": "+ validationResult['message'])
      }
    }

    if(isFrk && (selectedPurchaseOrder || selectedSubPurchase)){
      if (selectedPurchaseOrder == ""){
        arrErr.push("Purchase order is not selected")
      }
      if (selectedSubPurchase == ""){
        arrErr.push("Sub purchase order is not selected")
      }
      if (grossWeight == ""){
        arrErr.push("Gross Weight is not selected")
      }
      if (tareWeight == ""){
        arrErr.push("Tare Weight is not selected")
      }
      if (netWeight == ""){
        arrErr.push("Net Weight is not selected")
      }
      if(!truckDetail.length){
        arrErr.push("Truck details are not entered")
      }
      if (parseFloat(netWeight) !== parseFloat(grossWeight) - parseFloat(tareWeight)) {
        arrErr.push(`Net Weight should be equal to Gross Weight - Tare Weight`);
      } 
      truckDetail.forEach((data, idx) => {
        const {
          vehicle,
          driverName,
          contactNumber,
          licenseNumber,
          adhaarNumber,
          size,
          quantity,
        } = data;
        if (!vehicle || !driverName || !licenseNumber || !size || !quantity || !adhaarNumber) {
          arrErr.push(`All fields in truck ${idx + 1} for truck detail are mandatory.`);
        }

        if(!/^.{1,12}$/.test(vehicle)){
          arrErr.push(`Invalid vehicle number in truck ${idx + 1}. Please enter a valid vehicle number`)
        }

        if(!/^.{1,20}$/.test(licenseNumber)){
          arrErr.push(`Invalid license number in truck ${idx + 1}, please enter a valid license number`)
        }
    
        if (!/^\d{10}$/.test(contactNumber)) {
          arrErr.push(`Invalid contact number in truck ${idx + 1}. Please enter a valid 10-digit contact number.`);
        }

        if(!/^\d{12}$/.test(adhaarNumber)){
          arrErr.push(`Invalid aadhaar number in truck ${idx + 1}. Please enter a valid 12-digit aadhaar number(Eg. 432167895678).`)
        }
    
      });
    }
   
    validateLotSizeUnits(arrErr);
    let msg = "";
    arrErr.map((err) => (msg += err + "\n"));

    if (arrErr.length > 0) {
      dispatchNotification("error", arrErr);
    } else {
      setOpen(true);
    }
  };
  const handleClickCloseDialog = () => {
    setOpen(false);
  };
  const handleClickYes = () => {
    handleSubmit();
  };

  const dispatchNotification = (typ, msg) => {
    dispatch({
      type: "SHOW_NOTIFICATION",
      data: {
        open: true,
        type: typ,
        message: msg,
      },
    });
  };

  const handleChangeFrkId = (event) => {
    setFrkManufacturerId(event);
  };
  const handleChangeFrkType = (event) => {
    setFRKType(event.target.value);
    setManufacturerName("");
    setFrkManufacturerId("");
    setSelectedPurchaseOrder('');
  };

  const handleSubmit = () => {
    //  ( lotNo!=="" && frkManufacturerId!="" && value!="" && batchSizeLot.map(data=>(data.uomId!=="" && data.quantity!==" " && data.size!==" "))) &&
    let totalNoOfBags = 0;
    const submitData = {
      // manufacturerLotNumber: lotNo,
      lotProperties: [
        {
          name: 'manufacture_lotNumber',
          value: lotNo
        }
      ],
      commodityId: commodityId,
      targetManufacturerId: frkManufacturerId || targetManufId,
      dateOfDispatch: moment(value.$d?.toDateString()).format('yyyy-MM-DD'),
      sizeUnits: batchSizeLot.map((data) => ({
        uomId: data.uomId,
        quantity: data.quantity,
        size: data.size,
      })),
    };
  
    if (isFrk) {
      submitData.transportQuantityDetailsRequestDto = {
        purchaseOrderId: selectedPurchaseOrder,
        grossWeight: grossWeight,
        tareWeight: tareWeight,
        netWeight: netWeight,
        vehicleDetailsRequestDtos: truckDetail?.map((data)=>{
          totalNoOfBags = totalNoOfBags + +data.size
          return {
          childPurchaseOrderId: selectedSubPurchase,
          vehicleNo: data.vehicle,
          driverName: data.driverName,
          driverLicense: data.licenseNumber,
          driverContactNo: data.contactNumber,
          driverUid: data.adhaarNumber,
          totalBags: data.size,
          totalTruckQuantity: data.quantity
        }}),
        totalNoOfBags
      };
    }
  
    submitLotData(submitData);

  };

  const sizeUnitsDropdownData = batchSizeUnits
    ?.filter((d)=> d.isDispatched === false).map((d) => ({ ...d, sizeInKg: `${d.size}-${d?.conversionFactorKg}` }))
    .reduce((res, sizeUnit) => {
      if (sizeUnit.sizeInKg in res) {
        res[sizeUnit.sizeInKg].quantity += sizeUnit.quantity;
        return res;
      }
      return { ...res, [sizeUnit.sizeInKg]: sizeUnit };
    }, {});

  const validateLotSizeUnits = (newArr) => {
    if (!batchSizeLot || batchSizeLot.length === 0) {
      newArr.push(`select bags and quantity to dispatch a Lot`);
    }
    let quantityValid = true;
    let totalLotQuantity = 0;

    const batchSizeLotMap = batchSizeLot.reduce((res, sizeUnit) => {
      const quantity = parseFloat(sizeUnit.quantity, 10);
      if (isNaN(quantity) || quantity == 0 || quantity < 0) {
        quantityValid = false;
      } else {
        totalLotQuantity +=
          quantity * sizeUnit.size * sizeUnit.conversionFactorKg;
      }
      if (sizeUnit.sizeInKg in res) {
        res[sizeUnit.sizeInKg].quantity += quantity;
        return res;
      }
      return {
        ...res,
        [sizeUnit.sizeInKg]: {
          ...sizeUnit,
          quantity: quantity,
        },
      };
    }, {});
    if (!quantityValid) {
      newArr.push("Invalid quantity");
    }

    if (
      totalLotQuantity >
      batchRemainingQuantity.value * batchRemainingQuantity.conversionFactorKg
    ) {
      newArr.push(
        `Batch has only ${batchRemainingQuantity.value} ${batchRemainingQuantity.name}`
      );
    }
    return Object.keys(batchSizeLotMap).every((d) => {
      if (batchSizeLotMap[d]?.quantity <= sizeUnitsDropdownData[d]?.quantity)
        return true;
      newArr.push(
        `Only ${sizeUnitsDropdownData[d].quantity}
         bags of ${batchSizeLotMap[d]?.size} ${batchSizeLotMap[d]?.unit} are available`
      );
      return false;
    });
  };


  const handleChangeDate = (e) => {
    setValue(e);
  };
  const [selectedPurchaseOrder, setSelectedPurchaseOrder] = useState(''); 
  const [selectedSubPurchase, setSelectedSubPurchase] = useState('');

  const handlePurchaseOrder = (event) => {
    setSelectedPurchaseOrder(event.target.value);
    setSelectedSubPurchase('');
    setCommodityId("")
  };

  const handleSubPurchase= (event) => {
    setSelectedSubPurchase(event.target.value);
    purchaseOrderList[selectedPurchaseOrder]?.forEach(element => {
     if(element["childPurchaseOrderId"] == event.target.value){
      setCommodityId(element["commodityId"])
     }
    });
  };
  const [grossWeight, setGrossWeight] = useState('');
  const [tareWeight, setTareWeight] = useState('');
  const [netWeight, setNetWeight] = useState('');

  const handleGrossWeightChange = (event) => {
    const inputValue = event.target.value;
      setGrossWeight(inputValue);
  };

  const handleTareWeightChange = (event) => {
    const inputValue = event.target.value;
      setTareWeight(inputValue);
  };

  const handleNetWeightChange = (event) => {
    const inputValue = event.target.value;
      setNetWeight(inputValue);
  };

  const handleSearch = async ()=>{
    const targetManufData = await getTargetManufacturerFromLicense(targetManufLicenseNumber, categoryId)

    setTargetManufId(targetManufData?.data['id'])
    setTargetManufName(targetManufData?.data['name'])


    // setTargetManufId(returnedid)
    // setTargetManufName(returnedname)


  }

  const handleRadioSwitch = (e)=>{
    setManufSelectChoice(e.target.value)
    if(e.target.value == 'dropDown'){
      setLicenseError(false)
      setTargetManufId('')
      setTargetManufName('')
      setTargetManufLicenseNumber('')
    } else if(e.target.value == 'manufLicenseNo'){
      setManufacturerName('')
      setTargetManfId('');
      setSelectedPurchaseOrder('');
      setFrkManufacturerId('')
    }
  }

  return (
    <Box sx={{...ModalStyle, overflowY: 'auto', maxHeight: '80vh'}} >
      <Stack direction="column" spacing={2} alignItems="center">
        <Typography>{translate('batchNumber')}: {batchNo}</Typography>
        <Typography>
          {translate('remainingQuantity')}: {batchRemainingQuantity.value}{" "}
          {batchRemainingQuantity.name}
        </Typography>
        <Stack direction="row" spacing={2}>
          <Tooltip title="Manufacturer Lot Number(Optional)">
          <TextField
            name="manufacturerLotNumber"
            id="outlined-basic"
            value={lotNo}
            type="text"
            variant="outlined"
            label={translate('manufacturerLotNo')}
            onChange={(event) => setLotNo(event.target.value)}
            // error={submit && lotNo === "" && true}
            // disabled
            sx={{ width: 400 }}
          />
          </Tooltip>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
              label={translate('dispatchDate')}
              inputFormat="MM/DD/YYYY"
              value={value}
              minDate={new Date(lastActionDate)}
              maxDate={new Date()}
              onChange={handleChangeDate}
              renderInput={(params) => (
                <TextField {...params} sx={{ width: 400 }} inputProps={{ ...params.inputProps, readOnly: true }} />
              )}
              required
              error={true}
            />
          </LocalizationProvider>
        </Stack>

{/* {
    !currentLocation.pathname.includes('miller') && (

        <FormControl>
      <FormLabel id="controlled-radio-buttons-group">Manufacturer Select</FormLabel>
      <RadioGroup
        name="controlled-radio-buttons-group"
        value={manufSelectChoice}
        onChange={(e)=>{handleRadioSwitch(e)}}
      >
        <FormControlLabel value="dropDown" control={<Radio />} label={translate('dropdown')} />
        <FormControlLabel value="manufLicenseNo" control={<Radio />} label={translate('licenseNo')}/>
      </RadioGroup>
      </FormControl>
    )
} */}

      { (manufSelectChoice=='manufLicenseNo') &&
      <>
        <Stack direction="row" spacing={2}>
          <TextField 
           label={translate('manufacturerLicenseNo')}
           error={licenseError}
           type="string"
           helperText={licenseError && "Please enter a valid 14 digit license number"}
           value={targetManufLicenseNumber}
           onChange={(e)=>{
            if(!(/^\d*$/.test(e.target.value))){
              return;
            }
            if(e.target.value.toString().length != 14){
              setLicenseError(true)
            } else{
              setLicenseError(false)
            }
            setTargetManufLicenseNumber(e.target.value)
          }} 
           sx={{ width: 685, marginRight:'0px' }}></TextField>

           <Button variant="contained" disabled={licenseError} size="medium" onClick={handleSearch} sx={{ width: 100, alignSelf:'center', marginLeft:'0px' }}>{translate('search')}</Button>

        </Stack>

            <Stack direction="row" spacing={2}>

           <TextField value={targetManufName} label={translate('manufacturerName')} disabled sx={{ width: 800 }}></TextField>
            </Stack>
            </>
      }

      {

        (manufSelectChoice=='dropDown') &&
        
        <Stack direction="row" spacing={2}>
          <TextField
            className="dropdown-size"
            id="standard-select-currency"
            select
            label={translate('manufacturerType')}
            value={frkType}
            onChange={(e) => handleChangeFrkType(e)}
            variant="outlined"
            required
            error={submit && frkManufacturerId === "" && true}
            sx={{ width: 400 }}
          >
            <MenuItem key={"PUBLIC"} value={"PUBLIC"}>
              {translate('public')}
            </MenuItem>
            <MenuItem key={"PRIVATE"} value={"PRIVATE"}>
            {translate('private')}
            </MenuItem>
          </TextField>

          <Autocomplete
            disablePortal
            id="combo-box-demo"
            clearIcon={<ClearIcon fontSize="small" />}
            options={manuFacturerNames.map((option) => ({
              label: option.name,
              value: option.id,
            }))}
            filterOptions={options => options}
            value={manufacturerName}
            onInputChange={(e) => e && setQueryparam(e.target?.value)}
            onChange={(___, target) => {
              const { value, extra, label,externalId } = target || {};
              setFrkManufacturerId(value);
              setManufacturerName(label);
              setTargetManfId(value);
              setSelectedPurchaseOrder('');
            }}
            ListboxProps={{
              style: {
                maxHeight: "unset",
                overflow: "unset",
                boxShadow: "none",
                marginBottom: 5,
              },
              custom: {data:manuFacturerNames , count:manufacturerCount},
              fetchData: () => {
                fetchFrkManufacturers(pageNumber + 1);
              },
            }}
            ListboxComponent={ListboxComponent}
            onOpen={() => setQueryparam("")}
            required
            error={submit && frkManufacturerId === "" && true}
            renderInput={(params) => (
              <TextField
                {...params}
                size="medium"
                sx={{ width: 400 }}
                variant="outlined"
                label={translate('manufacturerName')}
              />
            )}
          />
        </Stack>
      }

        {isFrk && (
          <>
          <Stack direction="row" spacing={2} >
          <TextField
            className="dropdown-size"
            id="standard-select-currency"
            select
            label="Purchase Order"
            value={selectedPurchaseOrder}
            onChange={handlePurchaseOrder}
            variant="outlined"
            required
            error={submit && selectedPurchaseOrder === "" && true}
            sx={{ width: 400 }}
          >
            {Object.keys(purchaseOrderList)?.map((key) => (
              <MenuItem key={key} value={key}>
                {key}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            className="dropdown-size"
            id="standard-select-currency"
            select
            label="Sub Purchase Order"
            value={selectedSubPurchase}
            onChange={handleSubPurchase}
            variant="outlined"
            required
            error={submit && selectedSubPurchase === "" && true}
            sx={{ width: 400 }}
          >
         {purchaseOrderList[selectedPurchaseOrder]?.map((value, index) => (
            <MenuItem key={index} value={value["childPurchaseOrderId"]}>
              {value["childPurchaseOrderId"]}
            </MenuItem>
          ))}
          </TextField>
        </Stack>
        <Stack direction="row" spacing={2}>
        <Tooltip title="Please enter value in kgs" arrow>
        <TextField
          id="grossWeight"
          label="Gross Weight (in kgs)"
          variant="outlined"
          type="number"
          value={grossWeight}
          onChange={handleGrossWeightChange}
          sx={{ width: 261 }}
          inputProps={{ min: "0" }} 

        />
      </Tooltip>

      <Tooltip title="Please enter value in kgs" arrow>
        <TextField
          id="tareWeight"
          label="Tare Weight (in kgs)"
          variant="outlined"
          type="number"
          value={tareWeight}
          onChange={handleTareWeightChange}
          sx={{ width: 261 }}
          inputProps={{ min: "0" }} 

        />
      </Tooltip>

      <Tooltip title="Please enter value in kgs" arrow>
        <TextField
          id="netWeight"
          label="Net Weight (in kgs)"
          type="number"
          variant="outlined"
          value={netWeight}
          onChange={handleNetWeightChange}
                    sx={{ width: 261 }}
                    inputProps={{ min: "0" }} 

        />
      </Tooltip>
          </Stack>
          </>
        )}
        <BatchSizeComponent
          sizeUnitsDropdownData={sizeUnitsDropdownData}
          setBatchSizeLot={setBatchSizeLot}
          submit={submit}
        />
       {isFrk && (
        <TruckDetailComponent
          sizeUnitsDropdownData={sizeUnitsDropdownData}
          setTruckDetail={setTruckDetail}
          submit={submit}
        />
       )} 
        <Button variant="contained" onClick={handleClickOpenDialog}>
          {translate('dispatchLot')}
        </Button>
      </Stack>
      <div>
        <Dialog
          open={open}
          onClose={handleClickCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
          {translate('confirmationMsg')}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translate('areYouSureToDispatchLot')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleClickCloseDialog}
              variant="contained"
              sx={{ backgroundColor: "grey" }}
            >
              {translate('no')}
            </Button>
            <Button
              type="submit"
              variant="contained"
              onClick={handleClickYes}
              autoFocus
            >
              {translate('yes')}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
}
